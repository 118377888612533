<template>
  <div class="row">
    <div class="col-md-12">
      <h1>Lista płatności</h1>
    </div>
  </div>
  <div class="row">
<!--    <div class="col-md-5">-->
      <form @submit.prevent="displayPaymentList(1, 10000, userIdentity, salesPackageName, salesPackageCode)" class="form-inline">
        <div class="input-group">
          <div class="col-md-3>">
            <label for="userIdentity">Username or email:</label><br/>
            <input v-model="userIdentity" type="text" class="form-control" id="userIdentity">
          </div>
          <div class="col-md-3>">
            <label for="salesPackageName">Sales Package Name:</label><br/>
            <input v-model="salesPackageName" type="text" class="form-control" id="salesPackageName">
          </div>
          <div class="col-md-3>">
            <label for="salesPackageName">Sales Package Code:</label><br/>
            <input v-model="salesPackageCode" type="text" class="form-control" id="salesPackageCode">
          </div>
          <div class="col-md-3>">
            <br/>
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </div>
      </form>
<!--    </div>-->
<!--    <div class="col-md-7"></div>-->
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <template v-for="index in pages" :key="index">
        <button v-on:click="displayPaymentList(index)">{{index}}</button>&nbsp;&nbsp;
      </template>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table id="payments" class="list-table">
        <tr>
          <th style="width: 2%;">ID</th>
          <th style="width: 5%;">Username</th>
          <th style="width: 5%;">User email</th>
          <th style="width: 5%;">Created At</th>
          <th style="width: 2%;">Updated At</th>
          <th style="width: 2%;">Provider</th>
          <th style="width: 2%;">Internal Status</th>
          <th style="width: 2%;">Provider Status</th>
          <th style="width: 5%;">Internal Payment ID</th>
          <th style="width: 5%;">Provider Payment ID</th>
          <th style="width: 2%;">Value</th>
          <th style="width: 2%;">Sales Package Discount</th>
          <th style="width: 2%;">Sales Package Name</th>
          <th style="width: 2%;">Sales Package Code</th>

        </tr>

        <template v-for="payment in paymentList" v-bind:key="payment.id">
          <tr>
            <td>{{ payment.id }}</td>
            <td>{{ payment.user.username }}</td>
            <td>{{ payment.user.email }}</td>
            <td>{{ payment.createdAt}}</td>
            <td>{{ payment.updatedAt}}</td>
            <td>{{ payment.provider}}</td>
            <td>{{ payment.internalStatus.status}}</td>
            <td>{{ payment.providerStatus}}</td>
            <td>{{ payment.internalPaymentId}}</td>
            <td>{{ payment.providerPaymentId }}</td>
            <td>{{ payment.value ? payment.value/100 : null}}</td>
            <td>{{ payment.salesPackageDiscount ? payment.salesPackageDiscount/100 : null}}</td>
            <td><span class="fm-link pointer" v-on:click="redirectToSalesPackageByName(payment.salesPackageName)">{{ payment.salesPackageName }}</span></td>
<!--            <td>{{ payment.salesPackageName }}</td>-->
            <td>{{ payment.salesPackageCode }}</td>
            <!--        <td><router-link v-bind:to="'/admin/partner/' + partner.id" tag="button">Wyświetl</router-link></td>-->
          </tr>
        </template>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <template v-for="index in pages" :key="index">
        <button v-on:click="displayPaymentList(index)">{{index}}</button>&nbsp;&nbsp;
      </template>
    </div>
    <div class="col-md-2"></div>
  </div>
</template>

<script>
import PaymentService from '../../../services/admin/Payment/paymentService';
import { SalesPackageService } from '../../../services/admin/SalesPackage/salesPackageService';

export default {
  name: 'PaymentList',
  data() {
    return {
      userIdentity: null,
      salesPackageName: null,
      salesPackageCode: null,
      paymentList: [],
      pages: null
    };
  },
  mounted() {
    this.displayPaymentList();
  },
  methods: {
    async displayPaymentList(page = 1, limit = 100, userIdentity = null, salesPackageName = null, salesPackageCode = null) {
      const response = await PaymentService.getPaymentsList(page, limit, userIdentity, salesPackageName, salesPackageCode);
      const payments = response.data.payments;
      this.paymentList = payments.items;
      const totalCount = payments.totalCount;

      this.pages = Math.ceil((totalCount / limit));
    },
    async redirectToSalesPackageByName(salesPackageName) {
      const response = await SalesPackageService.getSalesPackageDataByName(salesPackageName);
      const salesPackage = response.data.salesPackage;
      const salesPackageId = salesPackage.items[0].id;

      await this.$router.push({
        name: 'SalesPackageDetails',
        params: { id: salesPackageId }
      });
    }
  }
};
</script>

<style scoped>
.list-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even){background-color: #f2f2f2;}

.list-table tr:hover {background-color: #ddd;}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.fm-link {
  text-decoration: underline;
  color: blue;
}
.pointer {
  cursor: pointer;
}
</style>
