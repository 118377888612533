import requestService from '../../requestService';

export class PaymentService {
  static async getPaymentsList(page = 1, limit = 10000, userIdentity = null, salesPackageName = null, salesPackageCode = null) {
    let path = '/rest/payment/payments?';
    path = page ? path + '&page=' + page : path;
    path = limit ? path + '&limit=' + limit : path;
    path = userIdentity ? path + '&userIdentity=' + userIdentity : path;
    path = salesPackageName ? path + '&salesPackageName=' + salesPackageName : path;
    path = salesPackageCode ? path + '&salesPackageCode=' + salesPackageCode : path;

    return requestService.makeGetRequest(path);
  }
}

const paymentService = PaymentService;
export default paymentService;
